// client/src/pages/user/Profile.js
import React from 'react';
import { UserSettings, MessageList } from 'components';

const Profile = () => {
   return (
       <div>
        <div><h1>User Profile</h1></div>
        <div className='two-column fill'>
           <div className='left'>
           <UserSettings />
           </div> 
           <MessageList />
           </div>
       </div>
   );
};

export default Profile;