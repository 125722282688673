// client/src/components/messages/MessageList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MessageList = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/messages');
            setMessages(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to load messages');
            setLoading(false);
        }
    };

    const handleDelete = async (messageId) => {
        try {
            await axios.delete(`/api/messages/${messageId}`);
            setMessages(messages.filter(msg => msg._id !== messageId));
        } catch (err) {
            setError('Failed to delete message');
        }
    };

    if (loading) return <div>Loading messages...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <h2>Your Messages</h2>
            {messages.length === 0 ? (
                <div>No messages</div>
            ) : (
                <div>
                    {messages.map(message => (
                        <div key={message._id}>
                            <div>{message.type === 'broadcast' ? 'Broadcast' : 'Message'}</div>
                            <div>From: {message.sender.name}</div>
                            <div>{new Date(message.createdAt).toLocaleString()}</div>
                            <div>{message.message}</div>
                            <button onClick={() => handleDelete(message._id)}>
                                Delete
                            </button>
                            <hr />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MessageList;