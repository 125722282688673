// client/src/routes/AdminRoutes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Admin, CreatePage, EditPage, BroadcastMessage } from 'pages';
import { ProtectedAdminRoute } from 'components';
import { featTemplate } from '../templates/pageTemplates';

function AdminRoutes() {
   return (
       <Routes>
           <Route index element={<ProtectedAdminRoute><Admin /></ProtectedAdminRoute>} />
           <Route path="page/createfeat" element={<ProtectedAdminRoute><CreatePage template={featTemplate} /></ProtectedAdminRoute>} />

           <Route path="page/feat/create" element={<ProtectedAdminRoute><CreatePage template={featTemplate} /></ProtectedAdminRoute>} />
           <Route path="page/create" element={<ProtectedAdminRoute><CreatePage /></ProtectedAdminRoute>} />
           <Route path="page/edit" element={<Navigate to="/pages" replace />} />
           <Route path="page/edit/:id" element={<ProtectedAdminRoute><EditPage /></ProtectedAdminRoute>} />
           <Route path="page/broadcast" element={<ProtectedAdminRoute><BroadcastMessage /></ProtectedAdminRoute>} />
       </Routes>
   ); 
}

export default AdminRoutes;