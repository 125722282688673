//client/src/pages/views/ShortStoryView.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { MarkdownRenderer, DefaultBlock, SectionBlock, DetailsBlock, RecentStories, AdminEditButton } from 'components';
import config from '../../config';
import '../../styles/pages/views/shortStoryView.css'

function ShortStoryView() {    
    const { id } = useParams();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [imageError, setImageError] = useState(false);
    const shouldShowImageTitle = page?.pageImg && !imageError;

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await axios.get(`${config.serverUrl}/api/pages/${id}`, { withCredentials: true });
                setPage(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch equipment'); // <-- Change error message
            } finally {
                setLoading(false);
            }
        };
        fetchPage();
    }, [id]);

    const getAuthorFromDetails = (details) => {
        const authorDetail = details?.find(detail => detail.label === 'Author');
        return authorDetail?.value || null;
    };

    const AuthorDisplay = ({ details }) => {
        const author = getAuthorFromDetails(details);
        if (!author) return null;
        
        return (
            <div className="author-display">
                by {author}
            </div>
        );
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No shorts stories found</div>; 

    return (
        <div className="page-view-container"> {/* begin page container */}

{/* ---------------------------------------------------------------------- */}
{/* Enable Admin Functions                                                 */}
{/* ---------------------------------------------------------------------- */}
            <AdminEditButton id={id} />
{/* ---------------------------------------------------------------------- */}
{/* Page Header (Title and Trigger Warning)                                */}
{/* ---------------------------------------------------------------------- */}
            <h1>{page.name}</h1><hr className='hr-small fiction-hr-name' /> 

            {page.trigger && (
                <div className="trigger-warning">
                    <label>Trigger Warning:</label>
                    <MarkdownRenderer content={page.triggerDesc} />
                </div>
            )}
{/* ---------------------------------------------------------------------- */}
{/* Main Content (description/sidebar/image)                               */}
{/* ---------------------------------------------------------------------- */} 
<div className='two-column place-two-column-7030 fiction-two-column-6040'> {/* begin first layout div  */}
                <div className='grid-item'> {/* column 1, cell 1  */}
            
    {/* ------------------------------------------------------------------ */}
    {/* Main Content: Page Image                                           */}
    {/* ------------------------------------------------------------------ */}            
                    {page.pageImg !== "" && !imageError && (
                        <div className="ss-page-img-container">
                                <img 
                                    src={`/images/${page.pageImg}`} 
                                    alt={page.name}
                                    className="ss-page-img"
                                    onError={() => setImageError(true)}
                                />
                        </div>
                    )}
    {/* ------------------------------------------------------------------ */}
    {/* Main Content: page.detail.labe="Author" display                    */}
    {/* ------------------------------------------------------------------ */}  
                    <div className='author left'>
                        <AuthorDisplay details={page.details} />
                    </div>

    {/* ------------------------------------------------------------------ */}
    {/* Main Content: Details Block                                        */}
    {/* ------------------------------------------------------------------ */}  
                    {page.details?.length > 0 && (
                        <div className="page-details left">
                            {page.details.map((detail, index) => (
                                <div key={index} className="detail-item">
                                    <label className='lead-text'>{detail.label}: </label>
                                    <span>{detail.value}</span>
                                </div>
                            ))}
                        </div>
                    )}
    {/* ------------------------------------------------------------------ */}
    {/* Main Content: Page Description                                     */}
    {/* ------------------------------------------------------------------ */} 
                    {page.desc && (
                        <div className="page-field desc-div left">
                            {/* <label className=' desc-label'>Description:</label> */}
                            <MarkdownRenderer content={page.desc} />
                        </div>
                    )}
                    
            </div> {/* end column 1, cell 1  */}


                    <div className='recent-stories'><RecentStories currentPageId={page._id} />
                    </div>

        <div className='grid-item'> {/* column 1, cell 2  */} 
                    <h4>{page.secName}</h4>
                    {page.desc && (
                        <div className="page-field desc-div left">
                            <MarkdownRenderer content={page.secDesc} />
                        </div>
                    )}
        </div> {/* end column 1, cell 1  */} 
    </div> {/* end first layout div  */}








        </div> /// End Page View Container --------------------------------
    );
}

export default ShortStoryView; 