import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context';
import axios from 'axios';
import config from '../../../config';
import { PageDetails, PageSections } from 'components'; 
import { featTemplate } from '../../../templates/pageTemplates';

function CreatePage({ template }) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState({
        name: '',
        type: '',
        subType: ''
    });


    const templates = {
        feat: featTemplate
    };
    

    // Define the subtype options for each type
    const subtypeOptions = {
        Fiction: ['Short Story', 'History'],
        Place: ['Nation', 'City', 'POI'],
        Rule: ['Ability', 'Character', 'Equipment', 'Feat', 'Species', 'Magic', 'Spell'],
        Site: ['Update', 'Header', 'Footer']
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axios.post(
                `${config.serverUrl}/api/pages`,
                page,
                { withCredentials: true }
            );
            
            if (response.status === 201) {
                navigate('/admin');
            }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while creating the page');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPage(prev => {
            // If changing the type, reset the subType
            if (name === 'type') {
                return {
                    ...prev,
                    [name]: value,
                    subType: ''  // Reset subType when type changes
                };
            }
            return {
                ...prev,
                [name]: value
            };
        });
        setIsFormDirty(true);
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <div className="create-page-container">
            <h1>Create Page</h1>
            
            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}

            <form onSubmit={handleSubmit} >
                <div className="create-page-form three-column">
                <div className="grid-item">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={page.name}
                        onChange={handleChange}
                        required
                        maxLength={100}
                        placeholder='Name'
                    />
                </div>

                <div className='grid-item'>
                    <select
                        id="type"
                        name="type"
                        value={page.type}
                        onChange={handleChange}
                        className='select-menu'
                        required
                    >
                        <option value="">Type</option> 
                        <option value="Fiction">Fiction</option>
                        <option value="Place">Place</option>
                        <option value="Rule">Rule</option>
                        <option value="Site">Site</option>
                    </select>
                </div>

                <div className="grid-item">
                    <select
                        id="subType"
                        name="subType"
                        value={page.subType}
                        onChange={handleChange}
                        className='select-menu'
                        required
                        disabled={!page.type} // Disable if no type is selected
                    >
                        <option value="">Subtype</option>
                        {page.type && subtypeOptions[page.type].map(subtype => (
                            <option key={subtype} value={subtype}>
                                {subtype}
                            </option>
                        ))}
                    </select>
                </div>
                </div>

                <PageDetails 
                    details={page.details || []}
                    onChange={details => setPage(prev => ({...prev, details}))}
                />

                <PageSections 
                    sections={page.sections || []}
                    onChange={sections => setPage(prev => ({...prev, sections}))}
                />

                <select onChange={(e) => {
                    if (e.target.value) {
                        setPage(templates[e.target.value]);
                        setIsFormDirty(true);
                    }
                }}>
                    <option value="">Select Template</option>
                    <option value="feat">Feat</option>
                </select>



                <div className="floating-action-button-container">
                    <button 
                        type="button" 
                        className="floating-action-button secondary" 
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        className={`floating-action-button ${!isFormDirty ? 'secondary' : ''}`}
                        disabled={!isFormDirty}
                    >
                        Create Page
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CreatePage;