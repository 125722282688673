//client/src/pages/views/EquipmentView.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'context';
import axios from 'axios';
import { DefaultBlock, SectionBlock, DetailsBlock, AdminEditButton } from 'components';
import config from '../../config';

function EquipmentView() {    
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await axios.get(`${config.serverUrl}/api/pages/${id}`, { withCredentials: true });
                setPage(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch equipment'); 
            } finally {
                setLoading(false);
            }
        };
        fetchPage();
    }, [id]);


    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No equipment found</div>;

    return (
        <div className="equipment-view">    

<AdminEditButton id={id} />


{/* ------------------------------------------------------------------ */}
{/* Page Content                                                       */}
{/* ------------------------------------------------------------------ */}


{/* ------------------------------------------------------------------ */}
{/* Page Title & Image                                                 */}
{/* ------------------------------------------------------------------ */}
            <div>
                <DefaultBlock 
                    page={page} 
                    include={['name']}
                    className="left" 
                />
                <hr/>
            </div>

            {page.pageImg && (
                <div className="">
                    <DefaultBlock 
                        page={page} 
                        include={['pageImg']}
                        className="img-right img-300-nat"
                        noDefaultStyles={true}
                    />
                </div>
            )}


{/* ------------------------------------------------------------------ */}
{/* Main Detail Block                                                  */}
{/* ------------------------------------------------------------------ */}

            <div className='left'>    
                <DetailsBlock 
                    details={page.details} 
                    excludeLabels={['isOgl', 'Source', 'isStock', 'Publisher']} 
                    className=""
                />
            </div>

{/* ------------------------------------------------------------------ */}
{/* Main Description                                                   */}
{/* ------------------------------------------------------------------ */}

            <div className="equip-desc">
                <DefaultBlock 
                    page={page} 
                    include={['desc']}
                    className=""
                    />
            </div>

{/* ------------------------------------------------------------------ */}
{/* Page Sections                                                      */}
{/* ------------------------------------------------------------------ */}

            <div className="feat-content left">
                <SectionBlock 
                    sections={page.sections}
                    noDefaultStyles={true}
                    />
            </div>



        </div>
    );
}

export default EquipmentView;  // <-- Change export name