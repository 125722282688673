// src/pages/admin/Admin.js
import React from 'react';
import { Link } from 'react-router-dom';
import './admin.css';

function Admin() {
 return (
   <div className="admin-container">
     <h1>Admin Dashboard</h1>
     <div className="admin-menu">
       <div className="admin-section">
         <h2>Create</h2>
         <Link to="/admin/page/create" className="admin-menu-item">Page</Link>
         <Link to="/admin/place/create" className="admin-menu-item">Place</Link>
         <Link to="/admin/fiction/create" className="admin-menu-item">Fiction</Link>
         <Link to="/admin/link/create" className="admin-menu-item">Link</Link>
         <Link to="/admin/page/broadcast" className="admin-menu-item">Broadcast</Link>
       </div>
       <div className="admin-section">
         <h2>Edit</h2>
         <Link to="/pages" className="admin-menu-item">Page</Link>
         <Link to="/places" className="admin-menu-item">Place</Link>
         <Link to="/admin/fiction/edit" className="admin-menu-item">Fiction</Link>
         <Link to="/admin/link/edit" className="admin-menu-item">Link</Link>
       </div>
     </div>
   </div>
 );
}

export default Admin;