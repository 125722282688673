// src/components/NavBar/NavLinks.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'context';

const NavLinks = ({ className = '' }) => {
  const { user } = useAuth();

  return (
    <div className={className}>
      <Link to="/" className="navbar-link">Home</Link>
      <Link to="/fiction" className="navbar-link">Fiction</Link>
      <Link to="/world" className="navbar-link">World</Link>
      <Link to="/rules" className="navbar-link">Rules</Link>
      {user && user.isAdmin && (
        <Link to="/admin" className="navbar-link">Admin</Link>
      )}
    </div>
  );
};

export default NavLinks;