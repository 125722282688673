// src/pages/world/PlaceList.js
import React from 'react';
import { HistoryStories, NationsList } from 'components';

const PlaceList = () => {
  // Components and their titles in an array for easier management
  const sections = [
    { title: 'History', Component: HistoryStories },
    { title: 'Nations', Component: NationsList },
  ];

  return (
    <div>
      <h1>Places</h1>
      <hr className='hr-small' />
      <div className='two-column center'>
        {sections.map(({ title, Component }) => (
          <React.Fragment key={title}>
            <Component render={content => {
              // If the content is empty message, don't render the div
              if (content === "No stories found" || content === "No nations found") {
                return null;
              }
              
              // Otherwise, render the section with its title and content
              return (
                <div>
                  <h2>{title}</h2>
                  {content}
                </div>
              );
            }} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PlaceList;