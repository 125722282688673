// src/pages/Home.js
import React from 'react';
import { useAuth } from 'context';
import { RecentStories, RecentUpdates } from 'components';


const Home = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="text-center">
      <h1 className="text-4xl font-bold mb-8">Welcome to Veylantia</h1>
      <div className='two-column' >
      <RecentUpdates />
      <RecentStories/>
    </div>
    </div>
  );
};

export default Home;