//client/src/style/index.js

import './themeLight.css';
import './themeDark.css';
import './icons/iconEdit.css';
import './icons/iconSave.css';
import './icons/iconDelete.css'
import './icons/iconSwitch.css';
import './icons/iconCreate.css'
import './quoteStyles.css';
import './banners.css';
import './textStyles.css';
import './hrStyles.css';
import './imageStyles.css';
import './divStyles.css'
import './boxStyles.css';
import './buttonStyles.css';
import './form-fields/formFields.css';
import './form-fields/textArea.css';
import './grids/Columns.css';
import './pages/buildingBlocks.css'
import './app.css'


export const applyTheme = (theme) => {
  console.log('Applying theme:', theme);
  document.documentElement.setAttribute('data-theme', theme);
  document.body.className = theme;
};

// Apply default theme
applyTheme('light');