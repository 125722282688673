// client/src/components/pages/PageDetails.js
import React from 'react';
import { DeleteIcon, CreateIcon } from 'components';
import './pageDetails.css'

function PageDetails({ details, onChange }) {
    const addDetail = () => {
        const newDetail = {
            label: '',
            value: '' 
        };
        onChange([...details, newDetail]); 
    };

    const updateDetail = (index, field, value) => {
        const updatedDetails = [...details];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [field]: value
        };
        onChange(updatedDetails);
    };

    const deleteDetail = (index) => {
        onChange(details.filter((_, i) => i !== index));
    };

    return (
        <div className="page-details">
                

                <button 
                    type="button" 
                    onClick={addDetail} 
                    className="create-button">
                        <CreateIcon /> 
                </button>

                <div className=''>

                        {details.map((detail, index) => (
                            
                            <div key={index} className="detail-item">
                                    <label htmlFor={`detail-label-${index}`}></label>
                                        <input
                                            type="text"
                                            id={`detail-label-${index}`}
                                            value={detail.label || ''}
                                            onChange={(e) => updateDetail(index, 'label', e.target.value)}
                                            placeholder="Label"
                                            className='small-label'
                                        />

                                    <label htmlFor={`detail-value-${index}`}></label>
                                        <input
                                            type="text"
                                            id={`detail-value-${index}`}
                                            value={detail.value || ''}
                                            onChange={(e) => updateDetail(index, 'value', e.target.value)}
                                            placeholder="Value"
                                            className='big-desc'
                                        />

                                    <button 
                                        type="button" 
                                        onClick={() => deleteDetail(index)}
                                        className="delete-button"
                                        >
                                            <DeleteIcon />
                                    </button>



                            </div> 
                        ))}

                </div>
        </div>
    );
}

export default PageDetails;