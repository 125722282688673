// src/components/site/Footer.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MarkdownRenderer, NavLinks } from 'components';
import '../../styles/components/footer.css'

const Footer = () => {
  const [footerContent, setFooterContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFooter = async () => {
      try {
        const response = await axios.get('/api/pages', {
          params: { name: 'Footer' }
        });
        
        const footer = response.data.find(page => page.name === 'Footer');
        
        if (footer) {
          const fullFooter = await axios.get(`/api/pages/${footer._id}`);
          setFooterContent(fullFooter.data);
        }
      } catch (err) {
        setError('Error loading footer');
      }
    };

    fetchFooter();
  }, []);

  if (error) return <div>{error}</div>;
  if (!footerContent) return <div>Loading...</div>;

  return (
    <div className='footer'>
      {footerContent.desc && (
        <div className="footer-page">  
          <MarkdownRenderer content={footerContent.desc} />
        </div>
      )}
      {footerContent.sections?.map((section, index) => (
        <div key={index} className=""> 
          {section.desc && <MarkdownRenderer content={section.desc} />}
        </div>
      ))}
      <NavLinks />
    </div>
  );
};

export default Footer;