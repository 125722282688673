//client/src/pages/view/AllFeatView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AdminEditButton, DefaultBlock, SectionBlock, DetailsBlock } from 'components';
import config from '../../config';

const AllFeatView = () => {
  const [featsPage, setFeatsPage] = useState(null);
  const [feats, setFeats] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the container page
        const pageResponse = await axios.get(
          `${config.serverUrl}/api/pages?type=Rule&subType=Character&tags=linkoverride&link.url=/feats`, 
          { withCredentials: true }
        );
        if (pageResponse.data.length > 0) {
          setFeatsPage(pageResponse.data[0]);
        }

        // Fetch the actual feats
        const featsResponse = await axios.get(
          `${config.serverUrl}/api/pages?type=Rule&subType=Feat&select=name,type,subType,desc,pageImg,details,sections`, 
          { withCredentials: true }
        );
        setFeats(featsResponse.data);
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to fetch feats');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!feats.length) return <div>No feats found</div>;

  return (
    <div className="feat-list-view">
              <h1>Feats</h1>
              <hr className='hr-small'/>

  {featsPage && <AdminEditButton id={featsPage._id} />}

      {feats.map(feat => (
        <div key={feat._id} className="feat-view">


          <div>
            <DefaultBlock 
              page={feat} 
              exclude={['secName', 'secDesc', 'desc', 'pageImg']} 
              className="left" 
              titleLevel = 'h2'
            />
          </div>

          <div className="detail-block-leading-subtext left">
            <DetailsBlock 
              details={feat.details} 
              excludeLabels={['']} 
              detailLabels={['Type']}
              className="details-block-type"
              hideLabels={true}
              inline={true}
              noDefaultStyles={true}
            /> <span> Feat</span>

            {feat.details?.find(detail => detail.label === 'Prerequisites')?.value && (
              <>
                <span> (</span>
                <DetailsBlock 
                  details={feat.details} 
                  excludeLabels={['']} 
                  detailLabels={['Prerequisites']}
                  className="details-block-prereq"
                  inline={true}
                  noDefaultStyles={true}
                />
                <span>)</span>
              </>
            )}
          </div>

          {feat.pageImg && (
            <div className="img-div">
              <DefaultBlock 
                page={feat} 
                include={['pageImg']}
                className="img-right img-circle img-300"
              />
            </div>
          )}

          <div className="feat-header">
            <DefaultBlock 
              page={feat} 
              include={['desc']}
            />
          </div>
          
          <div className="feat-content left">
            <SectionBlock 
              sections={feat.sections}
              noDefaultStyles={true}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllFeatView;