// src/config.js
const getServerUrl = () => {
  // If we're on veylantia.io, use that as server URL
  if (window.location.hostname === 'veylantia.io') {
    return 'https://veylantia.io';
  }
  // If we're on the Heroku staging domain, use that
  if (window.location.hostname === 'veylantia-site-443282c17454.herokuapp.com') {
    return 'https://veylantia-site-443282c17454.herokuapp.com';
  }
  // Default to localhost for development
  return 'http://localhost:5001';
};

const config = {
  serverUrl: getServerUrl()
};

export default config;