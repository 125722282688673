import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AllFeatView, PageList, PageView } from 'pages';

const RulesRoutes = () => {
  return (
    <Routes>
      <Route index element={<PageList />} />
      <Route path="feats" element={<AllFeatView />} />
      <Route path=":id" element={<PageView />} />
    </Routes>
  );
};

export default RulesRoutes;