import React from 'react';
import { MarkdownEditor, CollapsiblePanel, DeleteIcon } from 'components';


function PageSections({ sections, onChange }) {
    const addSection = () => {
        const newSection = {
            name: '',
            desc: '',
            secImg: '',
            secName: '',
            secDesc: '',
            quoteAuth: '',
            quoteDesc: '',
            quoteImg: ''
        };
        onChange([...sections, newSection]);
    };

    const updateSection = (index, field, value) => {
        const updatedSections = [...sections];
        updatedSections[index] = {
            ...updatedSections[index],
            [field]: value
        };
        onChange(updatedSections);
    };

    const deleteSection = (index) => {
        onChange(sections.filter((_, i) => i !== index));
    };

    return (
        <div className="page-sections">


{sections.map((section, index) => (
    <React.Fragment key={index}>
                <CollapsiblePanel title={`Section ${index + 1}`}>  {/* Outer panel with numbered title */}

        <div className="three-column left">

{/* ------------------------------------------------------------------------- */}
{/* Row 1                                                                     */}
{/* ------------------------------------------------------------------------- */}            
            <div className="form-group">
                <label htmlFor={`section-name-${index}`}>Name</label>
                <input
                    type="text"
                    id={`section-name-${index}`}
                    value={section.name || ''}
                    onChange={(e) => updateSection(index, 'name', e.target.value)}
                    required
                    maxLength={100}
                    placeholder='Name'
                />
            </div>

            <div className="form-group">
                <label htmlFor={`section-img-${index}`}>Image URL</label>
                <input
                    type="text"
                    id={`section-img-${index}`}
                    value={section.secImg || ''}
                    onChange={(e) => updateSection(index, 'secImg', e.target.value)}
                    placeholder='Image URL'
                />
            </div>

            <div className='form-group'>            
                <button 
                    type="button" 
                    onClick={() => deleteSection(index)}
                    className="delete-button"  
                >
                    <DeleteIcon />
                </button>
            </div>

{/* ------------------------------------------------------------------------- */}
{/* Row 2                                                                     */}
{/* ------------------------------------------------------------------------- */}
            <div className='form-group full-width'>
                <CollapsiblePanel title="Description">
                    <div className="">
                        <MarkdownEditor
                            value={section.desc || ''}
                            onChange={(e) => updateSection(index, 'desc', e.target.value)}
                            label="Description"
                            name={`section-desc-${index}`}
                        />
                    </div>
                </CollapsiblePanel>
            </div>

{/* ------------------------------------------------------------------------- */}
{/* Row 3                                                                     */}
{/* ------------------------------------------------------------------------- */}
            <div className='form-group full-width'>
                <CollapsiblePanel title="Sidebar">

                    <div className="form-group">
                        <label htmlFor={`section-secname-${index}`}></label>
                        <input
                            type="text"
                            id={`section-secname-${index}`}
                            value={section.secName || ''}
                            onChange={(e) => updateSection(index, 'secName', e.target.value)}
                            placeholder="Name"
                        />
                    </div>

                    <div className="form-group">
                        <MarkdownEditor
                            value={section.secDesc || ''}
                            onChange={(e) => updateSection(index, 'secDesc', e.target.value)}
                            label=""
                            name={`section-secdesc-${index}`}
                        />
                    </div>                                          
                </CollapsiblePanel>
            </div>

{/* ------------------------------------------------------------------------- */}
{/* Row 4                                                                     */}
{/* ------------------------------------------------------------------------- */}

            <div className='form-group full-width'>
                <CollapsiblePanel title="Quote">

                    <div className="quote-section three-column">

                        <div className="form-group">
                            <label htmlFor={`section-quoteauth-${index}`}>Author</label>
                            <input
                                type="text"
                                id={`section-quoteauth-${index}`}
                                value={section.quoteAuth || ''}
                                onChange={(e) => updateSection(index, 'quoteAuth', e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`section-quoteimg-${index}`}>Image URL</label>
                            <input
                                type="text"
                                id={`section-quoteimg-${index}`}
                                value={section.quoteImg || ''}
                                onChange={(e) => updateSection(index, 'quoteImg', e.target.value)}
                            />
                        </div>
                        <div></div>

                        <div className="form-group full-width">
                            <MarkdownEditor
                                value={section.quoteDesc || ''}
                                onChange={(e) => updateSection(index, 'quoteDesc', e.target.value)}
                                label="Quote Text"
                                name={`section-quotedesc-${index}`}
                            />
                        </div>

                    </div>
                </CollapsiblePanel>
            </div>

        </div>
        </CollapsiblePanel>

    </React.Fragment>
))}

<button type="button" onClick={addSection}>
    Add Section
</button>
        </div>
    );
}

export default PageSections;