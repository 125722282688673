//client/src/components/pages/building-blocks/DetailsBlock.js
import React from 'react';

const DetailsBlock = ({ 
  details, 
  className = '', 
  excludeLabels = [],
  detailLabels = [],
  hideLabels = false, 
  hideValues = false,
  inline = false,
  noDefaultStyles = false    
}) => {
  if (!details?.length) return null;

  const labelFilteredDetails = detailLabels.length > 0
    ? details.filter(detail => detailLabels.includes(detail.label))
    : details;

  const filteredDetails = labelFilteredDetails.filter(detail => 
    !excludeLabels.includes(detail.label)
  );

  if (!filteredDetails.length) return null;

  return (
    <div className={`${noDefaultStyles ? '' : 'page-details'} ${className}`} style={{ display: inline ? 'inline' : 'block' }}>
      {filteredDetails.map((detail, index) => (
        <div key={index} className={noDefaultStyles ? '' : 'detail-item'} style={{ display: inline ? 'inline' : 'block' }}>
          {!hideLabels && (
            <label className={noDefaultStyles ? '' : 'lead-text'}>{detail.label}: </label>
          )}
          {!hideValues && (
            <span>{detail.value}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default DetailsBlock;