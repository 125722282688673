//client/src/components/pages/building-blocks/DefaultBlock.js
import React from 'react';
import { MarkdownRenderer } from 'components';

const DefaultBlock = ({ 
  page,
  exclude = [],
  include = [],
  className = '',
  inline = false,
  noDefaultStyles = false,
  titleLevel = 'h1',
  unwrapDivs = false
}) => {
  if (!page) return null;

  const shouldShowField = (fieldName) => {
    if (include.length > 0) {
      return include.includes(fieldName) && !exclude.includes(fieldName);
    }
    return !exclude.includes(fieldName);
  };

  const renderTitle = () => {
    switch(titleLevel) {
      case 'h1': return <h1 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h1>;
      case 'h2': return <h2 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h2>;
      case 'h3': return <h3 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h3>;
      case 'h4': return <h4 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h4>;
      case 'h5': return <h5 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h5>;
      case 'h6': return <h6 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h6>;
      case 'span': return <span className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</span>;
      default: return <h1 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h1>;
    }
  };

  if (unwrapDivs) {
    return (
      <>
        {shouldShowField('name') && page.name && renderTitle()}
        {shouldShowField('pageImg') && page.pageImg && (
          <img 
            src={`/images/${page.pageImg}`} 
            alt={page.name}
            className={`${noDefaultStyles ? '' : 'page-img'} ${className}`} 
          />
        )}
        {shouldShowField('desc') && page.desc && (
          <MarkdownRenderer content={page.desc} />
        )}
        {shouldShowField('secName') && page.secName && (
          <h4>{page.secName}</h4>
        )}
        {shouldShowField('secDesc') && page.secDesc && (
          <MarkdownRenderer content={page.secDesc} />
        )}
      </>
    );
  }

  return (
    <div className={`${noDefaultStyles ? '' : 'page-view-container'} ${className}`}
         style={{ display: inline ? 'inline' : 'block' }}> 
      {shouldShowField('name') && page.name && (
        <div className={noDefaultStyles ? '' : 'page-header'}>
          {renderTitle()}
        </div>
      )}

      {shouldShowField('pageImg') && page.pageImg && (
        <div className={noDefaultStyles ? '' : 'page-img-container'}>
          <img 
            src={`/images/${page.pageImg}`} 
            alt={page.name}
            className={`${noDefaultStyles ? '' : 'page-img'} ${className}`} 
          />
        </div>
      )}

      {shouldShowField('desc') && page.desc && (
        <div className={`${noDefaultStyles ? '' : 'page-field desc-div left'}`}>
          <MarkdownRenderer content={page.desc} />
        </div>
      )}

      {shouldShowField('secName') && page.secName && (
        <h4>{page.secName}</h4>
      )}

      {shouldShowField('secDesc') && page.secDesc && (
        <div className={`${noDefaultStyles ? '' : 'page-field desc-div left'}`}>
          <MarkdownRenderer content={page.secDesc} />
        </div>
      )}
    </div>
  );
};

export default DefaultBlock;