//client/src/components/tools/PageTabs.js
// components/pages/PageTabs/PageTabs.js
import React, { useState } from 'react';
import '../../styles/components/pageTabs.css';

export const PageTab = ({ title, children }) => {
  // This is just a wrapper component for organization
  return children;
};

export const PageTabContainer = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  
  // Filter out any non-PageTab children
  const tabs = React.Children.toArray(children).filter(
    child => React.isValidElement(child) && child.type === PageTab
  );

  const titles = tabs.map(tab => tab.props.title);

  return (
    <div className="tabs-container">
      <div className="tabs">
        {titles.map((title, index) => (
          <button 
            key={index}
            className={`tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {title}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {tabs[activeTab]}
      </div>
    </div>
  );
};