// client/src/pages/Characters.js
import React from 'react';
import { EmbersCharacterList } from 'components';

const Characters = () => (
  <div className="page">
    <EmbersCharacterList />
  </div>
);

export default Characters;