//client/src/components/RecentUpdates.js
import React, { useState, useEffect } from 'react';
import config from '../../config';
import { MarkdownRenderer } from 'components';
import Icons from '../tools/Icons';
import { useAuth } from 'context';

const RecentUpdates = ({ currentPageId }) => {
    const [recentUpdates, setRecentUpdates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, isAuthenticated } = useAuth();

    useEffect(() => {
        const fetchRecentUpdates = async () => {
            try {
                const response = await fetch(`${config.serverUrl}/api/pages/recent-updates`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) throw new Error('Failed to fetch recent updates');
                
                const data = await response.json();
                setRecentUpdates(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRecentUpdates();
    }, [currentPageId]);

    if (loading) return <div>Loading recent updates...</div>;
    if (error) return null;
    if (recentUpdates.length === 0) return null;

    return (
        <div className='left relative'>
            {isAuthenticated && user?.isAdmin && (
                <button 
                    className="floating-icon create-icon" 
                    onClick={() => console.log('Create clicked')}
                    title="Create Update"
                >
                    <Icons.CreateIcon />
                </button>
            )}

            <h3>Recent Updates</h3>
            <div>
                {recentUpdates.map(update => (
                    <div key={update._id}>
                        <div>
                            {update.desc && <MarkdownRenderer content={update.desc} />}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecentUpdates;