//client/src/pages/pages/PageList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { PageRoute } from 'components';


function PageList() {
    const [pages, setPages] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await axios.get(
                    `${config.serverUrl}/api/pages`,
                    { withCredentials: true }
                );
                setPages(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch pages');
            } finally {
                setLoading(false);
            }
        };

        fetchPages();
    }, []);

    const groupPagesByType = (pages) => {
        const grouped = pages.reduce((acc, page) => {
            if (!acc[page.type]) {
                acc[page.type] = [];
            }
            acc[page.type].push(page);
            return acc;
        }, {});

        // Sort pages within each type by sortOrder or name
        Object.keys(grouped).forEach(type => {
            grouped[type].sort((a, b) => {
                if (a.sortOrder !== b.sortOrder) {
                    return (a.sortOrder || 0) - (b.sortOrder || 0);
                }
                return a.name.localeCompare(b.name);
            });
        });

        return grouped;
    };

    if (loading) return <div>Loading pages...</div>;
    if (error) return <div className="error-message">{error}</div>;

    const groupedPages = groupPagesByType(pages);
    const types = Object.keys(groupedPages).sort(); // Sort types alphabetically

    return (
        <div className="page-list-container">
            <h1>Pages</h1>
            <div className="pages-list">
                {pages.length === 0 ? (
                    <div className="no-pages">No pages found</div>
                ) : (
                    types.map(type => (
                        <div key={type} className="page-type-group">
                            <h2 className="page-type-header">{type}</h2>
                            <div className="page-type-items">
                            {groupedPages[type].map(page => (
    <div key={page._id} className="page-item">
        <PageRoute page={page}>
            {page.name} - {page.subType}
        </PageRoute>
    </div>
))}


                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default PageList;