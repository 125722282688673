// client/src/pages/admin/pages/EditPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PageTab, PageTabContainer, PageFields, PageSections, PageDetails, CancelSaveButtons } from 'components';
import { useFormDirty } from 'hooks';
import config from '../../../config';
import '../../../styles/pages/editPage.css'

function EditPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const isDirty = useFormDirty(page);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await axios.get(
                    `${config.serverUrl}/api/pages/${id}?populate=link`,  
                    { withCredentials: true }
                );
                setPage(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch page');
            } finally {
                setLoading(false);
            }
        };

        fetchPage();
    }, [id]);

    const handlePageFieldsChange = (updatedFields) => {
        setPage(updatedFields);
    };

    const handleDetailsChange = (updatedDetails) => {
        setPage(prev => ({
            ...prev,
            details: updatedDetails
        }));
    };

    const handleSectionsChange = (updatedSections) => {
        setPage(prev => ({
            ...prev,
            sections: updatedSections
        }));
    };

    const handleSave = async () => {
        setSaving(true);
        setError('');
        
        try {
            await axios.put(
                `${config.serverUrl}/api/pages/${id}`,
                page,
                { withCredentials: true }
            );
            navigate(-1);
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to save changes');
            setSaving(false);
        }
    };

    if (loading) return <div>Loading page...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No page found</div>;



    return (
        <div className="edit-page-container">
            <h1>Edit Page</h1>

            <PageTabContainer>
                <PageTab title="Base Page">
                    <PageFields 
                        page={page} 
                        onChange={handlePageFieldsChange} 
                    />
                </PageTab>

                <PageTab title="Details">
                    <PageDetails 
                        details={page.details || []} 
                        onChange={handleDetailsChange}
                    />
                </PageTab>

                <PageTab title="Sections">
                    <PageSections 
                        sections={page.sections || []} 
                        onChange={handleSectionsChange}
                    />
                </PageTab>
            </PageTabContainer>

            <CancelSaveButtons 
                onSave={handleSave}
                saving={saving}
                isDirty={isDirty} 
            />
        </div>
    );
}

export default EditPage;