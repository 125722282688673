//client/src/context/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
 const [user, setUser] = useState(null);
 const [loading, setLoading] = useState(true);
 const [isAuthenticated, setIsAuthenticated] = useState(false);

 useEffect(() => {
   checkUser();
 }, []);

 const checkUser = async () => {
   try {
     const res = await axios.get(`${config.serverUrl}/auth/current_user`, { 
       withCredentials: true 
     });
     setUser(res.data);
     setIsAuthenticated(true);
   } catch (err) {
     setUser(null);
     setIsAuthenticated(false);
   } finally {
     setLoading(false);
   }
 };

 return (
   <AuthContext.Provider value={{ user, loading, isAuthenticated }}>
     {children}
   </AuthContext.Provider>
 );
};

export const useAuth = () => useContext(AuthContext);